import React from 'react'
import Layout from '../components/layout' 
import { Container, Row, Col } from 'reactstrap'
import ppImage from '../images/faq.jpg'
import { BookAnAppoinment } from '../components/book0an-apoinment'
const TNC = () => {
    return (
        <Layout
            seo={{ title: "FAQ's- solutionsconsultants.com.au", description: '', keyword: '' }}
            banner={<PPBanner />}
            navStayle="SIMPLE"
            setUid={() => {} }
        >
            <Container style={{marginTop:50}} >
                 
                <Row style={{
                    border: "1px solid #eee",
                    padding: 30,
                    borderRadius: 10,
                    backgroundColor: '#f9f9f9',
                    marginBottom: 50
                }}>
                    <Col>
                    <h2>FAQ's Content Here</h2>

                    </Col>
                </Row>
            </Container>
            <BookAnAppoinment />
        </Layout>
    )
}

const PPBanner = () => {
    return (
        <div style={{ backgroundImage: `url(${ppImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container>
                <Row style={{ paddingTop: '100px' }}>
                    <Col md={6}>
                        <h2 style={{ marginBottom: 0, textShadow: ' rgb(0, 0, 0) 1px 1px 1px, rgb(49, 49, 49) 3px 3px 5px', color: '#fff', fontWeight: 600 }}>FAQ's </h2>
                    </Col> 
                    <Col style={{ textAlign: 'right' }} md={6}>
                        <a href="/1-on-1-phone-call" className="btn btn-round btn-warning" style={{ fontWeight: 800 }}>1-on-1 Meeting</a>
                    </Col>
                </Row>
                <hr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.58)' }} />
                <Row style={{ marginTop: 50, paddingBottom: 50, textAlign: "center" }}>
                    <Col>
                        <h2 style={{ textShadow: ' rgb(0, 0, 0) 1px 1px 1px, rgb(49, 49, 49) 3px 3px 5px', color: '#fff' }}> 
                        Got questions? We’ve got answers 
                        </h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TNC